export enum EUserLanguages {
    RUS = 'russian',
    ARM = 'armenian',
    ENG = 'english',
    FRA = 'french',
    GER = 'german',
    HINDI = 'hindi',
    SPA = 'spanish',
    ITA = 'italian',
    POR = 'portuguese',
    CHI = 'chinese',
    JPN = 'japanese',
    KOR = 'korean',
    ARA = 'arabic',
    TUR = 'turkish',
    UKR = 'ukrainian',
    POL = 'polish',
    DUT = 'dutch',
    SWE = 'swedish',
    NOR = 'norwegian',
    DAN = 'danish',
    FIN = 'finnish',
    GRE = 'greek',
    HEB = 'hebrew',
    THA = 'thai',
    VIE = 'vietnamese',
    IND = 'indonesian',
    MAL = 'malay',
    BEN = 'bengali',
    URD = 'urdu',
    PER = 'persian',
    TGL = 'tagalog',
    CZE = 'czech',
    HUN = 'hungarian',
    SLO = 'slovak',
    SRB = 'serbian',
    CRO = 'croatian',
    BUL = 'bulgarian',
    ROM = 'romanian',
    LIT = 'lithuanian',
    LAT = 'latvian',
    EST = 'estonian',
    SLV = 'slovenian',
    ISL = 'icelandic',
    AFR = 'afrikaans',
    SWA = 'swahili',
    ZUL = 'zulu'
}